<template lang='pug'>
div
  base-header(class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success")
  b-container(fluid class="mt--9")
    b-row
      b-col
        el-table(class="table-responsive table" header-row-class-name="thead-light" :data='orders')
          el-table-column(label="ID" prop="id")
          el-table-column(label="Date")
            template(v-slot="{row}")
              span {{row.date.slice(0,10)}} {{row.date.slice(11,16)}}
          el-table-column(label="Sum" prop="price")
          el-table-column(label="Vend Status")
            template(v-slot='{row}')
              i(v-if='row.vend_status' class='ni ni-check-bold')
              i(v-if='row.vend_status==false' class='ni ni-fat-remove')
          el-table-column(label="Vending" prop="vending")
          el-table-column(label='Status' prop='status')
          el-table-column(label='Receipt')
            template(v-slot="{row}")
              b-link(v-if='row.receipt_link' :href='row.receipt_link') link
          el-table-column(label='Refund')
            template(v-slot="{row}")
              b-button(v-if='row.status == "PC" && row.qr_payment_id' size='sm' variant='primary' @click='refund(row.id)') Refund
          //el-table-column(label="Active" prop="active")
          //  template(v-slot="{row}")
              b-checkbox(disabled :value='row.active')
          //el-table-column(label="Receipt" prop="receipt_enabled")
            template(v-slot="{row}")
              b-checkbox(disabled :value='row.receipt_enabled')
          //el-table-column(label="Description" prop="description")
</template>
<script>
import { Table, TableColumn} from 'element-ui'
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      orders: []
    }
  },
  methods: {
    refund(order_id) {
      this.axios.post(`/partner/order_refund/`, {'order_id': order_id}).then( (response) => {
        console.log(response.data)
      }, (error) => {
        console.log(error)
      })
    }
  },
  async beforeMount() {
    let response = await this.axios.get(`/partner/orders/`)
    this.orders = response.data
  }
}
</script>
<style>
</style>
